import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import dcmLogo from '../imagens/logos/dcmLogo.png';
import '../styles/Footer.css';
import sloganImg from '../imagens/logos/slogan.png';
import plmLogoBlack from '../imagens/logos/plmLogoblack.png';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Login = ({ 
  loggedIn, 
  setLoggedIn, 
  setUsername,
  username,
  handleLogout, 
}) => {
  const [usernameInput, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [tokenInput, setTokenInput] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);

  const handleLogin = async () => {
    try {
      const response = await fetch(`${backendUrl}/authenticated-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
          username: usernameInput,
          password,
        }),
      });
  
      if (!response.ok) {
        if (response.status === 503) {
          // Exibir mensagem de manutenção
          document.getElementById('message').innerText = 'Site em manutenção. Por favor, tente novamente mais tarde.';
        } else {
          // Exibir mensagem de erro baseado no status
          const errorText = await response.text();
          throw new Error(errorText || 'Erro ao fazer login');
        }
      }
  
      const data = await response.json();
      const privilegio = data.user.privilegio;
      const client = data.user.client;
      console.log('Privilegio:', privilegio);
  
      if (data.success) {
        setLoggedIn(true);
        setUsername(usernameInput);
        setShowPopup(true);
        localStorage.setItem('username', usernameInput);
        sessionStorage.setItem('loggedIn', 'true');
        sessionStorage.setItem('client', client);
  
        if (privilegio === 'admin') {
          setUserRole('admin');
          sessionStorage.setItem('userRole', 'admin');
        } else {
          setUserRole('user');
          sessionStorage.setItem('userRole', 'user');
        }
  
        console.log('UserRole após setUserRole:', sessionStorage.getItem('userRole'), sessionStorage.getItem('client'));
  
        await getUserToken();
        alert('Inserir o código de segurança enviado para o e-mail de registo.');
      } else {
        alert('Credenciais inválidas');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      alert('Erro ao fazer login:', error);
    }
  };
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const getUserToken = async () => {
    const client = sessionStorage.getItem('client');
    const username = localStorage.getItem('username');
    try {
      const response = await fetch(`${backendUrl}/userToken/${client}/${username}`, {
        method: "GET",
      });

        if (!response.ok) {
          throw new Error("Erro ao buscar o token");
        }

      const data = await response.json();
      setToken(data[0].token);
      return data[0].token;
    } catch (error) {
      console.error("Erro ao buscar o token:", error.message);
    }
  };

  const tokenRedirect = async () => {
    const storedToken = await getUserToken();
    if (tokenInput === storedToken && loggedIn) {
      console.log('userroleToken:', userRole);
      if (userRole === 'admin') {
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    } else {
      alert('Token inválido');
    }
  };

  const popUpToken = () => {
    return (
      <div className="popupToken">
        {showPopup && (
          <input
            className='userInput'
            type="text"
            placeholder="Token"
            value={tokenInput}
            onChange={(e) => setTokenInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                tokenRedirect();
              }
            }}
          />
        )}
      </div>
    );
  };

  const handleButtonClick = () => {
    if(showPopup){
      tokenRedirect();
    }else{
      handleLogin();
    }
  }

  return (
    <div className="containerLogin">
      <div className="login">
        <img className="dcmLogo" src={dcmLogo} alt="Logo" />
        <div className="inputUser">
          <input
            className={`userInput ${showPopup ? 'inactive' : ''}`}
            type="text"
            placeholder="User"
            value={usernameInput}
            onChange={(e) => setUsernameInput(e.target.value)}
            onKeyDown={handleKeyDown}
            readOnly={showPopup}
          />
          <input
            className={`userInput ${showPopup ? 'inactive' : ''}`}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            readOnly={showPopup}
          />
          {popUpToken()}
          <button className="loginButton" onClick={handleButtonClick}>Entrar</button>
        </div>
      </div>
      <footer>
        <div className='footerContent'>
          <img className='sloganImg' src={sloganImg} alt='Logo' />
          <img className='plmLogoBlack' src={plmLogoBlack} alt='Logo' />
        </div>
      </footer>
    </div>
  );
};

export default Login;
